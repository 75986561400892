import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './weapons.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Range, getTrackBackground } from 'react-range';
import { WWWeaponEntry } from '../../modules/ww/weapons/ww-weapon';
import { Form } from 'react-bootstrap';
import lodash from 'lodash';
import { WWWeaponsReleaseJSON } from '../../modules/ww/data/ww-weapons-release';

const WWWeaponsPage: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [currentLevel, setCurrentLevel] = useState([1]);
  const [currentWeapon, setCurrentWeapon] = useState('All');
  const [visibleWeapons, setVisibleWeapons] = useState(WWWeaponsReleaseJSON);
  const [textSearch, setTextSearch] = useState('');

  const weaponChangeHandler = (e) => {
    setCurrentWeapon(e);
  };

  useEffect(() => {
    let filtered = WWWeaponsReleaseJSON;

    filtered = filtered.sort((a, b) => {
      let retval = 0;
      if (a.Rarity > b.Rarity) retval = -1;
      if (a.Rarity < b.Rarity) retval = 1;
      if (retval === 0) retval = a.Name < b.Name ? -1 : 1;
      return retval;
    });

    setVisibleWeapons(filtered);
    forceUpdate();
  }, []);

  useEffect(() => {
    let filtered = WWWeaponsReleaseJSON;

    if (currentWeapon != 'All') {
      filtered = filtered
        .sort((a, b) => {
          let retval = 0;
          if (a.Rarity > b.Rarity) retval = -1;
          if (a.Rarity < b.Rarity) retval = 1;
          if (retval === 0) retval = a.Name < b.Name ? -1 : 1;
          return retval;
        })
        .filter((weapon) => {
          if (weapon.Weapon_Type === currentWeapon) return true;
        });
    }

    if (textSearch != '') {
      filtered = filtered.filter(
        (echo) =>
          echo.Name &&
          echo.Name.toLowerCase().indexOf(textSearch.toLowerCase()) > -1
      );
      filtered = lodash.uniqBy(filtered, 'ID');
    }

    setVisibleWeapons(filtered);
    forceUpdate();
  }, [currentWeapon, textSearch]);

  const items = visibleWeapons.map((weapon, idx) => {
    return (
      <div key={idx}>
        <WWWeaponEntry name={weapon.Name} level={currentLevel} mode="icon" />
      </div>
    );
  });

  return (
    <DashboardLayout className={'generic-page ww-weapons-page'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>Weapons</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ww/categories/category_weapons.png"
            alt="Weapons"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Weapons</h1>
          <h2>All weapons available in Wuthering Waves.</h2>
          <p>
            Last updated: <strong>07/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Weapons"
        subtitle={
          <span className="additional-text">
            Showing {visibleWeapons.length} weapon
            {visibleWeapons.length !== 1 && 's'}
          </span>
        }
      />
      <div className="stats-header">
        <div className="info">
          <p>
            Please select the <strong>ascension</strong> of the weapon (1-5).
          </p>
        </div>
        <div className="level-changer">
          <span className="current-level">
            Lv. <span className={`level`}>{currentLevel}</span>
          </span>
          <div className={`level-slider `}>
            <Range
              step={1}
              min={1}
              max={5}
              values={currentLevel}
              onChange={(values) => setCurrentLevel(values)}
              renderTrack={({ props, children }) => (
                <div
                  role="button"
                  tabIndex={0}
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%'
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '5px',
                      width: '100%',
                      borderRadius: '0px',
                      background: getTrackBackground({
                        values: currentLevel,
                        colors: ['#FFD780', '#484950'],
                        min: 1,
                        max: 5
                      }),
                      alignSelf: 'center'
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    borderRadius: '0px',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      height: '14px',
                      width: '5px',
                      backgroundColor: isDragged ? '#FFD780' : '#484950'
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <Form.Control
        size="lg"
        type="text"
        placeholder="Search for Weapon"
        value={textSearch}
        onChange={(e) => setTextSearch(e.target.value)}
      />
      <div className="weapon-filter">
        <div
          className={`option all ${currentWeapon === 'All' && 'selected'}`}
          onClick={() => weaponChangeHandler('All')}
        >
          <h6>All weapons</h6>
        </div>
        <div
          className={`option Broadblade ${
            currentWeapon === 'Broadblade' && 'selected'
          }`}
          onClick={() => weaponChangeHandler('Broadblade')}
        >
          <h6>Broadblade</h6>
        </div>
        <div
          className={`option Gauntlets ${
            currentWeapon === 'Gauntlets' && 'selected'
          }`}
          onClick={() => weaponChangeHandler('Gauntlets')}
        >
          <h6>Gauntlets</h6>
        </div>
        <div
          className={`option Pistols ${
            currentWeapon === 'Pistols' && 'selected'
          }`}
          onClick={() => weaponChangeHandler('Pistols')}
        >
          <h6>Pistols</h6>
        </div>
        <div
          className={`option Rectifier ${
            currentWeapon === 'Rectifier' && 'selected'
          }`}
          onClick={() => weaponChangeHandler('Rectifier')}
        >
          <h6>Rectifier</h6>
        </div>
        <div
          className={`option Sword ${currentWeapon === 'Sword' && 'selected'}`}
          onClick={() => weaponChangeHandler('Sword')}
        >
          <h6>Sword</h6>
        </div>
      </div>
      <div className="ww-weapons-container">{items}</div>
    </DashboardLayout>
  );
};

export default WWWeaponsPage;

export const Head: React.FC = () => (
  <Seo
    title="Weapons | Wuthering Waves | Prydwen Institute"
    description="All weapons available in Wuthering Waves."
    game="ww"
  />
);
